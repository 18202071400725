import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SentIcon from "../images/icons8-sent.svg";
import SEO from "../components/seo";

export default () => (
  <>
    <SEO title="Thank you" />

    <Container maxWidth="md" style={{ paddingTop: "150px" }}>
      <img
        src={SentIcon}
        alt="Sent"
        style={{
          width: "200px",
          height: "200px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <br /> <br /> <br />
      <Typography component="div">
        <Box
          textAlign="center"
          component="h1"
          m={1}
          fontFamily="DM Serif Text"
          fontWeight="700"
          color="#3d3d3d"
          fontSize="h2.fontSize"
        >
          It&apos;s on the way!
        </Box>
        <br />
        <Box color="#3d3d3d" component="p" textAlign="center">
          Keep an eye on your inbox. We are sending the whitepaper to your email
          address.
        </Box>
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  </>
);
